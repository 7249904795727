<template>
  <div>
    <div>
      <section class="mb-4 d-flex align-center title-section">
        <Header
          :title="orgName"
          subscription=""
          :class="{ invisible: !orgName }"
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                data-ga="icon_org_dashboard_force_refresh"
                @click="forceRefresh()"
                class="mr-2 icon-hover"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Refresh</span>
          </v-tooltip>
        </Header>
      </section>
      <SummaryWidget
        :summary="summaryData"
        :openIssueTurbineCount="openIssueTurbineCount"
        :turbineCount="turbineCount"
        :loading="loading.getOrgData"
        @goToIssuesTable="goToIssuesTab"
        @goToChecksTab="goToChecksTab"
      />
    </div>
    <section class="mt-7 mb-3">
      <div class="d-flex">
        <v-tabs v-model="tab" class="mb-4">
          <v-tab
            v-for="(tab, index) in dashboardTabs"
            @click="handleTabClick(tab)"
            :key="index"
            class="no-background"
            data-ga="org_dashboard_tab"
          >
            {{ tab.label }}
          </v-tab>
        </v-tabs>
        <div class="switch-wrapper">
          <SwitchButton
            label="Show map"
            color="primary"
            @changeVal="changeShowMap"
          />
        </div>
      </div>
      <v-row>
        <v-col :lg="showMap ? 9 : 12">
          <v-tabs-items
            v-model="tab"
            class="no-background"
            v-if="dashboardTabs[tab]"
          >
            <v-tab-item
              v-for="tabItem in dashboardTabs"
              :key="tabItem.value"
              :transition="false"
            >
              <div class="dynamic-tabs-container">
                <template v-if="tabItem.value === 'checks'">
                  <KeepAlive>
                    <ChecksTable
                      v-if="dashboardTabs[tab].value === 'checks'"
                      :data="checks"
                      :loading="loading.getChecks"
                      sortBy="aep_loss_farm_pct_open"
                      :itemsPerPage="10"
                      :userHasInternalAccess="isInternal"
                      @goToIssuesTable="goToIssuesTab"
                      @getChecksTableData="callForChecks"
                    />
                  </KeepAlive>
                </template>
                <template v-if="tabItem.value === 'sites'">
                  <SiteTable
                    v-if="dashboardTabs[tab].value === 'sites'"
                    :headers="headers"
                    :data="tableData"
                    :loading="loading.getIssuesGroupedBySite"
                    :forceRefresh="isForceRefresh"
                    @goToIssuesTable="goToIssuesTab"
                    @resetForceRefresh="resetForceRefresh"
                  />
                </template>
                <template v-if="tabItem.value === 'oems'">
                  <OemTable
                    v-if="dashboardTabs[tab].value === 'oems'"
                    :data="issuesGroupedByOEM.results"
                    :loading="loading.getIssuesGroupedByOEM"
                    :itemsPerPage="10"
                    sortBy="aep_loss_farm_pct"
                    @getOemsTableData="callForOems"
                  />
                </template>
                <template v-if="tabItem.value === 'issues'">
                  <IssueTable
                    ref="issuesTable"
                    class="org-issue-table"
                    v-if="dashboardTabs[tab].value === 'issues'"
                    :data="paginatedIssuesForIssuesTable"
                    :loading="loading.getPaginatedIssuesForIssuesTable"
                    :totalItems="paginatedIssuesTotal"
                    :itemsPerPage="10"
                    :footerProps="{
                      'items-per-page-options': [5, 10, 15, 25, 50],
                    }"
                    @paginationUpdated="getNewItemsSet"
                  />
                </template>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col sm="12" md="12" lg="3" xl="3">
          <MapView
            v-if="showMap"
            :mapData="mapConfig"
            :isMapLoading="loading.getIssuesGroupedBySite"
          />
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import L from "leaflet";
import SummaryWidget from "@/components/SummaryWidget";
import SiteTable from "@/components/SiteTable";
import OemTable from "@/components/OemTable";
import IssueTable from "@/components/IssueTable";
import ChecksTable from "@/components/ChecksTable";
import MapView from "@/components/MapView";
import SwitchButton from "@/components/SwitchButton";
import {
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  clickCountLimit,
} from "@/helpers/variables";
import {
  roundToString,
  roundAepPct,
  getDashboardTabs,
  setUrlParams,
} from "@/helpers/functions";
import Header from "@/components/Header";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";

export default {
  name: "OrgDashboard",
  components: {
    SummaryWidget,
    SiteTable,
    OemTable,
    IssueTable,
    ChecksTable,
    MapView,
    SwitchButton,
    Header,
  },
  data() {
    return {
      orgId: this.$route.params.orgId,
      showMap: true,
      mapLoading: false,
      selected: "Month",
      headers: [
        { text: "Name", sortable: true, value: "site_name" },
        { text: "Subscription", sortable: false, value: "subscription" },
        { text: "Turbine model(s)", sortable: true, value: "turbine_models" },
        { text: "Open issues", sortable: true, value: "n_open" },
        {
          text: "Issues that need attention",
          sortable: true,
          value: "n_req_attn",
        },
        {
          text: "Unconfirmed issues",
          sortable: true,
          value: "n_unconfirmed",
        },
        {
          text: "AEP potential (%)",
          sortable: true,
          value: "aep_loss_farm_pct_open",
        },
      ],
      tab: 0,
      checksData: [],
      popupLoading: false,
      loadingNewItemSet: false,
      dashboardtabs: [],
      leftmostTab: "",
      newItemSetRequest: {},
      isForceRefresh: false,
      setUrlParams,
    };
  },
  computed: {
    ...mapState({
      orgData: (state) => state.org.orgData,
      paginatedIssuesForIssuesTable: (state) =>
        state.org.paginatedIssuesForIssuesTable,
      paginatedIssuesTotal: (state) => state.org.issuesTotal,
      checks: (state) => state.org.checks,
      issuesGroupedBySite: (state) => state.org.issuesGroupedBySite,
      issuesGroupedByOEM: (state) => state.org.issuesGroupedByOEM,
      loading: (state) => state.org.loading,
      isInternal: (state) => state.user.userData.is_internal,
      clickCount: (state) => state.app.clickCount,
      userData: (state) => state.user.userData,
    }),
    tableData() {
      if (this.issuesGroupedBySite?.length > 0) {
        let subscription = "";
        return this.issuesGroupedBySite.map((item) => {
          if (
            item.sow_def_name === "Find, Fix, Measure" ||
            item.sow_def_name === "Find-Only"
          ) {
            subscription = "Pulse";
          } else {
            subscription = item.subscription;
          }

          return {
            ...item,
            selected: false,
            site_id: item.farm_id,
            site_name: item.farm_name,
            n_open: item.n_open ? item.n_open : 0,
            n_req_attn: item.n_require_external_input
              ? item.n_require_external_input
              : 0,
            unconfirmed: item.n_unconfirmed ? item.n_unconfirmed : 0,
            subscription: subscription,
            color: item.n_require_external_input > 0 ? "red" : "blue",
            position: L.latLng(item.latitude_deg, item.longitude_deg),
            mapInfoTitle: item.farm_name,
            mapInfoDesc: `Open issues: ${item.n_open ? item.n_open : 0}`,
            mapInfoRoute: `/sites/${item.farm_id}`,
          };
        });
      } else {
        return [];
      }
    },
    mapConfig() {
      const mapData = {
        zoom: 10,
        center: [0, 0],
        url: this.$vuetify.theme.isDark ? darkMapTileLayerUrl : mapTileLayerUrl,
      };
      if (this.tableData?.length > 0) {
        mapData.markers = this.tableData;
        return mapData;
      }
      return mapData;
    },
    issuesSummary() {
      if (this.orgData && "issues_summary" in this.orgData) {
        return this.orgData.issues_summary;
      } else {
        return null;
      }
    },
    openIssueTurbineCount() {
      if (this.orgData && "issues_summary" in this.orgData) {
        return this.orgData.issues_summary.open.turbine_ids.length;
      } else {
        return null;
      }
    },
    turbineCount() {
      if (this.orgData && "capacity" in this.orgData) {
        return this.orgData.capacity.n_turbines;
      } else {
        return null;
      }
    },
    orgName() {
      if (this.orgData.name) {
        return this.orgData.name;
      } else {
        return "";
      }
    },
    summaryData() {
      if (this.orgData && "capacity" in this.orgData && this.issuesSummary) {
        const unconfirmed = this.setSummaryUnconfirmed();
        return {
          capacity: {
            title: "Total capacity",
            mw: this.orgData.capacity.mw
              ? roundToString(this.orgData.capacity.mw, 1)
              : 0,
            nSites: this.orgData.capacity.n_sites
              ? this.orgData.capacity.n_sites
              : 0,
            nTurbines: this.orgData.capacity.n_turbines
              ? this.orgData.capacity.n_turbines
              : 0,
          },
          issue: {
            title: "Issues",
            needAttn: this.issuesSummary.requires_external_input.n
              ? this.issuesSummary.requires_external_input.n
              : 0,
            open: this.issuesSummary.open.n ? this.issuesSummary.open.n : 0,
            fixed: this.issuesSummary.fixed_or_resolved.n
              ? this.issuesSummary.fixed_or_resolved.n
              : 0,
            issueDefIdCount:
              this.issuesSummary.requires_external_input.turbine_issue_def_ids
                .length,
            unconfirmed: unconfirmed ? unconfirmed : 0,
          },
          totalGains: {
            title: "AEP gain from fixed issues",
            mwh: this.issuesSummary.fixed_or_resolved.aep_gain_mwhpyr
              ? roundToString(
                  this.issuesSummary.fixed_or_resolved.aep_gain_mwhpyr,
                  -1,
                )
              : 0,
            usd: this.issuesSummary.fixed_or_resolved.aep_gain_upper_usdpyr
              ? roundToString(
                  this.issuesSummary.fixed_or_resolved.aep_gain_upper_usdpyr,
                  -1,
                )
              : 0,
            percent: this.issuesSummary.fixed_or_resolved.aep_gain_farm_pct
              ? roundAepPct(
                  this.issuesSummary.fixed_or_resolved.aep_gain_farm_pct,
                )
              : 0,
          },
          aepPotential: {
            title: "AEP potential for open issues",
            mwh: this.issuesSummary.open.aep_loss_mwhpyr
              ? roundToString(this.issuesSummary.open.aep_loss_mwhpyr, -1)
              : 0,
            usd: this.issuesSummary.open.aep_loss_upper_usdpyr
              ? roundToString(this.issuesSummary.open.aep_loss_upper_usdpyr, -1)
              : 0,
            percent: this.issuesSummary.open.aep_loss_farm_pct
              ? roundAepPct(this.issuesSummary.open.aep_loss_farm_pct)
              : 0,
          },
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    ...mapActions({
      getOrgData: "org/getOrgData",
      getPaginatedIssuesForIssuesTable: "org/getPaginatedIssuesForIssuesTable",
      getChecks: "org/getChecks",
      getIssuesGroupedBySite: "org/getIssuesGroupedBySite",
      getIssuesGroupedByOEM: "org/getIssuesGroupedByOEM",
      incrementClickCount: "app/incrementClickCount",
    }),
    callInitialAPIs(refresh) {
      const orgId = +this.$route.params.orgId;
      // Retrieving org data, summary, and issues grouped by site (for map population)
      if (
        (Object.keys(this.orgData).length === 0 && !refresh) ||
        (Object.keys(this.orgData).length > 0 && refresh) ||
        this.orgData?.id !== orgId
      ) {
        this.getOrgData({
          orgId,
          issuesSummary: 1,
        });
      }
      if (
        (this.issuesGroupedBySite?.length === 0 && !refresh) ||
        orgId !== this.issuesGroupedBySite[0]?.org_id ||
        (this.issuesGroupedBySite?.length > 0 && refresh)
      ) {
        this.getIssuesGroupedBySite({ orgId });
      }
    },
    setHeaders() {
      if (!this.isInternal) {
        this.headers.splice(5, 1);
      }
    },
    handleTabClick(tab) {
      const url = new URL(window.location.href);
      url.search = "";
      url.hash = tab.value;
      if ((tab.value === "checks" || tab === 1) && url.hash !== "checks") {
        url.hash = "checks";
        url.searchParams.set("items_per_page", 10);
        url.searchParams.set("page", 1);
        url.searchParams.set("sort_by", "aep_loss_farm_pct_open");
        url.searchParams.set("sort_desc", 1);
        this.callForChecks("tabClicked");
      }
      history.replaceState(null, null, url);
    },
    goToTab() {
      const hashName = decodeURI(window.location.hash).split("#")[1];
      const tabIndex = this.dashboardTabs.findIndex(
        (x) => x.value === hashName,
      );
      if (tabIndex > -1) {
        this.tab = tabIndex;
      }
      // If tab is checks
      if (this.tab === 1 && this.checks?.length === 0) {
        this.callForChecks("goToTab");
      }
    },
    callForChecks(type) {
      // Type defines the functionality for each call
      const orgId = +this.$route.params.orgId;
      if (this.loading.getChecks) {
        return;
      }
      if (
        ((this.checks?.length === 0 || this.checks[0]?.org_id !== orgId) &&
          (this.tab === 1 || type === "tabClicked" || type === "gotToTab")) ||
        (this.checks?.length > 0 && type === "refresh")
      ) {
        this.getChecks({ orgId });
      }
    },
    setSummaryUnconfirmed() {
      if ("unconfirmed" in this.issuesSummary) {
        return this.issuesSummary.unconfirmed.n;
      }
      return null;
    },
    changeShowMap(value) {
      this.showMap = value;
      const eventLabel = `org_map_toggle_${value}`;
      // Track filter selections
      if (this.clickCount < clickCountLimit) {
        // Data sent to Google Analytics
        if (eventLabel) {
          this.incrementClickCount();
          gaTrackEvent(this.$gtag, {
            eventName: "first_clicks_after_login",
            eventCategory: "user_interaction",
            eventLabel: eventLabel.toLowerCase(),
            value: this.clickCount,
          });
        }
      }
    },
    async getNewItemsSet(payload) {
      this.loadingNewItemSet = true;
      const orgId = +this.$route.params.orgId;
      if (orgId) {
        await this.getPaginatedIssuesForIssuesTable({
          orgId,
          ...payload,
        });
      }
      // Set static request for forced refresh
      this.newItemSetRequest = payload;
      this.loadingNewItemSet = false;
    },
    callForOems() {
      const orgId = +this.$route.params.orgId;
      if (
        this.issuesGroupedByOEM?.results?.length === 0 ||
        this.issuesGroupedByOEM?.org_id !== orgId
      ) {
        this.getIssuesGroupedByOEM(orgId);
      }
    },
    goToIssuesTab(type, source, name) {
      for (const tab of this.dashboardTabs) {
        if (tab.value === "issues") {
          this.tab = this.dashboardTabs.indexOf(tab);
          break;
        }
      }
      const url = new URL(window.location.href);
      let tempUrl = null;
      let newUrl = null;
      url.search = "";
      // Set issue status filters
      if (type && source) {
        let params = {
          include_statuses: [],
          page: 1,
          items_per_page: 10,
          sort_by: "aep_loss_pct",
          sort_desc: 1,
        };
        if (type === "open") {
          params.include_statuses = ["new", "in_progress"];
        } else if (type === "fixed") {
          params.include_statuses = ["fixed"];
        } else if (type === "unconfirmed") {
          params.include_statuses = ["unconfirmed", "needs_review"];
        }
        if (name) {
          params.search_for = name;
        }
        // Call method inside issues table to set filters inside issues table
        if (url.hash === "#issues" && source === "summary") {
          this.$refs["issuesTable"][0].changeStatusFilters(
            params.include_statuses,
          );
        }
        tempUrl = this.setUrlParams(url, params, "include_status");
        url.hash = "issues";
        if (tempUrl) {
          newUrl = tempUrl;
        } else {
          newUrl = url;
        }
        history.replaceState(null, null, newUrl);
      } else {
        this.handleTabClick(this.tab);
      }
    },
    goToChecksTab() {
      for (const tab of this.dashboardTabs) {
        if (tab.value === "checks") {
          this.tab = this.dashboardTabs.indexOf(tab);
          break;
        }
      }
      this.handleTabClick(this.tab);
    },
    resetForceRefresh() {
      this.isForceRefresh = false;
    },
    async forceRefresh() {
      const orgId = +this.$route.params.orgId;
      const url = new URL(window.location.href);
      this.isForceRefresh = true;
      // Refresh all data that has been previously loaded
      if (orgId) {
        this.callInitialAPIs("refresh");
      }
      if (this.checks?.length > 0) {
        this.callForChecks("refresh");
      }
      if (this.issuesGroupedByOEM?.results?.length > 0) {
        this.getIssuesGroupedByOEM(orgId);
      }
      if (
        this.paginatedIssuesForIssuesTable?.length > 0 &&
        this.newItemSetRequest &&
        "page" in this.newItemSetRequest &&
        url.hash !== "#issues"
      ) {
        this.getNewItemsSet(this.newItemSetRequest);
      }
    },
  },
  async beforeMount() {
    const orgId = +this.$route.params.orgId;
    // Change this value to the desired leftmost tab
    this.leftmostTab = "sites";
    this.dashboardTabs = getDashboardTabs("orgs", this.leftmostTab);
    // fetch new org data if no org data or if org data does not match route
    if (orgId) {
      this.callInitialAPIs();
    }
  },
  mounted() {
    const url = new URL(window.location.href);
    this.setHeaders();
    if (!url.hash) {
      url.hash = this.leftmostTab;
    }
    this.goToTab();

    history.replaceState(null, null, url);

    window.addEventListener(
      "hashchange",
      () => {
        this.goToTab();
      },
      false,
    );
  },
  watch: {
    headers: {
      immediate: true,
      handler(data) {
        this.columns = data ? data : [];
        this.displayColumns = [...this.columns];
        this.issueStatus = [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.loader {
  margin: 0 auto 0 auto;
}

.title-section {
  height: 2.25rem;
}
</style>
